import styled from "styled-components";

export const Container = styled.div`
  padding: 3rem 1rem;
  height: calc(100vh - 60px);
  background-color: rgba(0, 0, 0, 0.3);
`
export const Wrapper = styled.div`
  display: flex;
  height: calc(100vh - 120px);
  flex-direction: column;
  justify-content: space-between;
  color: var(--gray-dark);
`
export const Navigation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  a {
    &.active {
      button {
        background-color: var(--blue);
      }
    }
  }
`

import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Loader } from "../../../components";
import { getTrainById } from "../../../lib";
import {
  Container,
  TicketsListContainer,
  TicketTrainCard,
  TrainCardHeader,
  TrainTicketsCounter,
  Wrapper,
} from "./styled";

export default function TicketsList() {
  const [data, setData] = useState({});
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openInputs, setOpenInputs] = useState(false);
  const trainId = useLocation().pathname.split("/").pop();

  useEffect(() => {
    try {
      (async () => {
        setLoading(true);
        const response = await getTrainById(trainId, (snapshot) => {
          setData(snapshot.val());
        });
        setData(response);
        setLoading(false);
      })();
    } catch (error) {
      console.log(error);
    }
  }, [trainId]);

  useEffect(() => {
    try {
      if (data) {
        setTickets([]);
        for (const prop in data.tickets) {
          setTickets((state) => [
            ...state,
            {
              ...data.tickets[prop],
              // seats: data.tickets[prop].seats,
              // id: data.tickets[prop].id,
            },
          ]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [data]);

  const columns = [
    { field: "id", headerName: "ID" },
    { field: "seats", headerName: "Seats" },
  ];

  if (loading) return <Loader />;
  else
    return (
      <Container>
        <Wrapper>
          <TicketsListContainer>
            <Link to="/dashboard/new-ticket">
              <Button
                sx={{ minWidth: 200 }}
                onClick={() => setOpenInputs(!openInputs)}
                variant="contained"
                color="secondary"
              >
                حجز تذكرة جديدة
              </Button>
            </Link>
            <TicketTrainCard>
              <TrainCardHeader>{trainId}</TrainCardHeader>
              <TrainTicketsCounter total={data.seats}>
                {data.unavailable} /
              </TrainTicketsCounter>
            </TicketTrainCard>
            <TableContainer>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column.field}>
                        {column.headerName}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tickets.map((prop) => (
                    <TableRow>
                      <TableCell sx={{ width: "100%" }}>{prop.id}</TableCell>
                      <TableCell sx={{ width: "100%" }}>{prop.seats}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </TicketsListContainer>
        </Wrapper>
      </Container>
    );
}

import styled from "styled-components";

export const Container = styled.div``
export const Wrapper = styled.div`
   margin: 1rem;
  
  
  a {
    color: var(--c-secondary);
  }
  section {
    margin-left: 1rem;
  }

  h3 {
    margin: 2rem 0;
  }
  
  p {
    margin: 0 1rem;
  }
  
  ul {
    margin: 0 4rem;
  }

`
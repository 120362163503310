import { Avatar, Button, IconButton } from "@mui/material";
import React from "react";
import { Container, Title, Wrapper } from "./styled";
import { NavLink, useNavigate } from "react-router-dom";
import FirebaseAuth from "../../config/FirebaseAuth";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { signOut } from "firebase/auth";
import useAuth from "../hooks/useAuth";

export default function Navbar() {
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const Navigate = useNavigate();
  const { auth, user } = useAuth();

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const userMenu = () => {
    const dashboard = {
      label: "الادارة",
      onClick: () => {
        Navigate("/dashboard");
        handleCloseUserMenu();
      },
    };
    const menu = [
      {
        label: "الملف الشخصي",
        onClick: () => {
          Navigate("/profile");
          handleCloseUserMenu();
        },
      },
      {
        label: "تسجيل الخروج",
        onClick: () => {
          signOut(FirebaseAuth);
          Navigate("/");
          handleCloseUserMenu();
        },
      },
    ];
    if (user && user?.admin) menu.unshift(dashboard);
    return menu;
  };

  return (
    <Container>
      <Wrapper>
        <Title>
          <NavLink to="/">قطاري</NavLink>
        </Title>
        {auth ? (
          <IconButton onClick={handleOpenUserMenu}>
            <Avatar
              src={auth?.photoURL}
              alt={auth?.displayName}
              sx={{ width: 35, height: 35 }}
            />
          </IconButton>
        ) : (
          <NavLink to="/login">
            <Button variant="contained">تسجيل دخول</Button>
          </NavLink>
        )}
        <Menu
          keepMounted
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          {userMenu().map((item, index) => (
            <MenuItem key={index} onClick={item.onClick}>
              {item.label}
            </MenuItem>
          ))}
        </Menu>
      </Wrapper>
    </Container>
  );
}

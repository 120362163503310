import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  position: relative;
`
export const Wrapper = styled.div`
  min-height: calc(100vh - 60px);
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
`
export const Title = styled.h2``
export const Form = styled.form`
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`
export const Group = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row-reverse;
`
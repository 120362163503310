import React from "react";
import { Container, Wrapper } from "./styled";

export default function Profile() {
  return (
    <Container>
      <Wrapper>غير متاح حاليا</Wrapper>
    </Container>
  );
}

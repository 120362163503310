import styled from "styled-components";

export const Container = styled.div``
export const Wrapper = styled.div`
  position: relative;
`
export const Header = styled.div`
  height: calc(100vh - 60px);
  width: 100%;
  overflow: hidden;
  video {
    width: 100%;
  }
  ::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
`

export const Content = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 88;

    .Typewriter {
      font-size: 5rem;
      /* background-color: rgba(0, 0, 0, 0.2); */
      font-weight: bold;
      padding: 0 2rem;
    }
`


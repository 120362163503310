import styled from "styled-components";

export const Container = styled.div`
  height: 60px;
  width: 100%;
  background-color: var(--blue);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 1.5rem -.4rem rgba(0, 0, 0, 0.3);
`
export const Wrapper = styled.div`
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const Title = styled.h1``

import React, { useEffect, useRef } from "react";
import { Outlet, Route, Routes, useNavigate } from "react-router-dom";
import { Loader, useAuth } from "../../components";
import Sidebar from "./Sidebar";
import { Container, Content, Wrapper } from "./styled";
import routes from "./outlet";

export default function Dashboard() {
  const { auth, user } = useAuth();
  const navigate = useNavigate();
  const timer = useRef();

  useEffect(() => {
    if (timer.current && user) clearTimeout(timer.current);
    else {
      clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        if (!user && !user?.admin) return navigate("/");
      }, 3000);
    }
  }, [user]);

  if (!auth) return <Loader fullScreen />;
  return (
    <Container>
      <Wrapper>
        <Sidebar />
        <Content>
          <Routes>
            {routes.map((prop, key) => (
              <Route key={key} path={prop.path} element={prop.element} />
            ))}
          </Routes>
          <Outlet />
        </Content>
      </Wrapper>
    </Container>
  );
}

import Main from "./Main";
import Suggestions from "./suggestions";
import TicketForm from "./ticketForm";
import TicketsList from "./TicketsList";
import TrainsList from "./TrainsList";
import UsersList from "./UsersList";

const routes = [
  {
    path: 'users',
    element: <UsersList />
  },
  {
    path: "suggestions",
    element: <Suggestions />
  },
  {
    path: "trains",
    element: <TrainsList />
  },
  {
    path: "trains/:id",
    element: <TicketsList />
  },
  {
    path: "/",
    element: <Main />
  },
  {
    path: "new-ticket",
    element: <TicketForm />
  }
]

export default routes;
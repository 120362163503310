import { Button, Slider, TextField } from "@mui/material";
import {
  DesktopDatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import React, { useRef, useState } from "react";
import SelectList from "../../../../components/SelectList";
import {
  Actions,
  Container,
  FormContainer,
  Group,
  ModalActions,
  ModalContainer,
  ModalHeader,
  SliderContainer,
  TicketCard,
  TicketCardActions,
  TicketCardContent,
  TicketCardHeader,
  TicketCardPrice,
  TicketCardTag,
  TicketsContainer,
  Wrapper,
} from "./styled";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Cities from "../../../../lib/cities.json";
import seatClass from "../../../../lib/seatClass.json";
import {
  addNewUnlistedTicket,
  getTrainTicketsList,
  updateTrainTickets,
} from "../../../../lib";
import QRCode from "react-qr-code";
import { v4 as uuidv4 } from "uuid";
import Modal from "../../../../components/Modal";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";

export default function TicketForm() {
  const printRef = useRef();

  const initialization = {
    endStation: "",
    startStation: "",
    departureTime: new Date().toLocaleString(),
    arrivalTime: new Date().toLocaleString(),
    departureDate: new Date().toLocaleDateString(),
    seatClass: "",
    seats: 1,
  };
  const [ticketData, setTicketData] = useState({
    id: null,
    startStation: null,
    endStation: null,
    arrivalTime: null,
    departureTime: null,
    validity: null,
    trainId: null,
    seatClass: null,
    carNumber: null,
    seats: null,
    amount: null,
    departureDate: null,
    trainType: null,
    order_id: null,
    status: null,
    transactions_id: null,
    createdAt: new Date().getTime(),
  });

  const [form, setForm] = useState(initialization);
  const [tickets, setTickets] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const handleChange = ({ target }) => {
    setForm((state) => ({ ...state, [target.name]: target.value }));
  };

  const onClearInputs = () => {
    setForm(initialization);
  };

  const onSearch = async (e) => {
    e.preventDefault();
    const response = await getTrainTicketsList(
      form.startStation,
      form.endStation
    );
    if (response) setTickets(response);
  };
  const onSelect = (prop) => {
    try {
      const id = uuidv4();
      setTicketData((state) => ({
        ...state,
        id,
        startStation: form.startStation,
        endStation: form.endStation,
        arrivalTime: prop.arrivalTime,
        departureDate: new Date(form.departureDate).toLocaleDateString(),
        departureTime: prop.departureTime,
        validity: new Date(form.departureDate).getTime() + 1000 * 60 * 60 * 24,
        seatClass: prop.seatClass,
        carNumber: null,
        seats: form.seatClass,
        trainType: null,
        order_id: null,
        status: null,
        transactions_id: null,
        trainId: prop.id,
        amount: prop.price * 100,
      }));
      setOpenModal(true);
    } catch (error) {
      console.error(error);
    }
  };

  const onFilter = (e) => {
    return e.seatClass.split(" ").join("") === form.seatClass;
  };

  const onPrint = useReactToPrint({
    content: () => printRef.current,
  });

  const onConfirm = async () => {
    try {
      await addNewUnlistedTicket(ticketData)
        .then(() => {
          return updateTrainTickets(ticketData.trainId, {
            tickets: {
              seats: form.seats,
              id: ticketData.id,
            },
            seats: form.seats,
          });
        })
        .then(() => toast.success("تم حجز التذكرة بنجاح"))
        .catch(console.error);
      setOpenModal(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container>
      <Wrapper>
        <FormContainer onSubmit={onSearch}>
          <Group>
            <SelectList
              items={Cities}
              required
              onChange={(e) =>
                handleChange({
                  target: { name: "startStation", value: e.target.value },
                })
              }
              name="startStation"
              label="محطة القيام"
              value={form.startStation}
            />
            <SelectList
              items={Cities}
              required
              name="endStation"
              onChange={(e) =>
                handleChange({
                  target: { name: "endStation", value: e.target.value },
                })
              }
              label="محطة الوصول"
              value={form.endStation}
            />

            <SelectList
              items={seatClass}
              onChange={(e) =>
                handleChange({
                  target: { name: "seatClass", value: e.target.value },
                })
              }
              required
              name="seatClass"
              label="الدرجة"
              value={form.seatClass}
            />
          </Group>
          <Group>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                onChange={(value) =>
                  handleChange({ target: { name: "departureTime", value } })
                }
                required
                sx={{ minWidth: 200, m: 1 }}
                label="وقت القيام"
                name="departureTime"
                value={form.departureTime}
                renderInput={(params) => <TextField {...params} />}
              />
              <TimePicker
                onChange={(value) =>
                  handleChange({ target: { name: "arrivalTime", value } })
                }
                required
                sx={{ minWidth: 200, m: 1 }}
                label="وقت الوصول"
                name="arrivalTime"
                value={form.arrivalTime}
                renderInput={(params) => <TextField {...params} />}
              />
              <DesktopDatePicker
                onChange={(value) =>
                  handleChange({ target: { name: "departureDate", value } })
                }
                required
                sx={{ minWidth: 200, m: 1 }}
                label="تاريخ القيام"
                name="departureDate"
                value={form.departureDate}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Group>

          <SliderContainer>
            <span>عدد المسافرين</span>
            <Slider
              color="secondary"
              aria-label="Small"
              valueLabelDisplay="on"
              min={1}
              max={4}
              marks
              step={1}
              defaultValue={1}
              value={form.seats}
              onChange={(e) =>
                handleChange({
                  target: { name: "seats", value: e.target.value },
                })
              }
            />
          </SliderContainer>

          <Actions>
            <Button type="submit" variant="contained" color="secondary">
              بحث
            </Button>
            <Button onClick={onClearInputs} variant="contained" color="error">
              مسح
            </Button>
          </Actions>
        </FormContainer>
        <TicketsContainer>
          {tickets &&
            tickets?.length !== 0 &&
            tickets.filter(onFilter).map((prop, index) => (
              <TicketCard key={index}>
                <TicketCardHeader>
                  <span>
                    {Cities.find((x) => x.id === form.startStation).name}
                  </span>
                  <i className="fa-solid fa-arrow-left"></i>
                  <span>
                    {Cities.find((x) => x.id === form.endStation).name}
                  </span>
                </TicketCardHeader>
                <TicketCardContent>
                  <TicketCardTag>
                    <span>الدرجة</span>
                    <p>{prop.seatClass}</p>
                  </TicketCardTag>
                  <TicketCardTag>
                    <span>وقت الوصول</span>
                    <p>{prop.arrivalTime}</p>
                  </TicketCardTag>
                  <TicketCardTag>
                    <span>وقت القيام</span>
                    <p>{prop.departureTime}</p>
                  </TicketCardTag>
                  <TicketCardTag>
                    <span>نوع القطار</span>
                    <p>{prop.type}</p>
                  </TicketCardTag>
                  <TicketCardTag>
                    <span>رقم القطار</span>
                    <p>{prop.id}</p>
                  </TicketCardTag>
                </TicketCardContent>
                <TicketCardPrice>{prop.price * form.seats}</TicketCardPrice>
                <TicketCardActions>
                  <Button
                    size="large"
                    onClick={() => onSelect(prop)}
                    variant="contained"
                    color="secondary"
                  >
                    حجز
                  </Button>
                </TicketCardActions>
              </TicketCard>
            ))}
        </TicketsContainer>
        <Modal ref={printRef} open={openModal} setOpen={setOpenModal}>
          <ModalContainer>
            <ModalHeader>
              <span>{ticketData.amount} قرش</span>
              <span>{ticketData.amount / 100} جنيه</span>
            </ModalHeader>
            <QRCode value={ticketData.id} />
            <ModalActions>
              <Button
                onClick={onConfirm}
                size="large"
                variant="contained"
                color="secondary"
              >
                تأكيد الحجز
              </Button>
              <Button
                onClick={onPrint}
                size="large"
                variant="contained"
                color="warning"
              >
                طباعة
              </Button>
            </ModalActions>
          </ModalContainer>
        </Modal>
      </Wrapper>
    </Container>
  );
}

import { getApp, getApps, initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "mytrainapp-2fa76.firebaseapp.com",
  databaseURL: "https://mytrainapp-2fa76-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "mytrainapp-2fa76",
  storageBucket: "mytrainapp-2fa76.appspot.com",
  messagingSenderId: "669664321485",
  appId: "1:669664321485:web:580bc840215259c85cee84",
  measurementId: "G-NLXS1JV4J3"
};


let app;
if (!getApps().length) app = initializeApp(firebaseConfig);
else app = getApp();

const FirebaseAuth = getAuth(app);

export default FirebaseAuth

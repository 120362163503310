import React from "react";
import "./style.css";

export default function Loader({ fullScreen }) {
  return (
    <div className={fullScreen && "container"}>
      <div className="spinner"></div>
    </div>
  );
}

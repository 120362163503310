import React from "react";
import main_background from "../../assets/main_background2.mp4";
import { Container, Wrapper, Header, Content } from "./styled";
import Typewriter from "typewriter-effect";

export default function Home() {
  return (
    <Container>
      <Wrapper>
        <Header>
          <video autoPlay loop muted>
            <source src={main_background} type="video/mp4" />
          </video>
          <Content>
            <Typewriter
              options={{
                loop: true,
                cursor: ""
              }}
              onInit={(typewriter) => {
                typewriter
                  .typeString("إبد رحلتك بطريقة افضل الان")
                  .pauseFor(2500)
                  .deleteAll()
                  .typeString("The best way to travel")
                  .pauseFor(2500)
                  .deleteAll()
                  .start();
              }}
            />
          </Content>
        </Header>
      </Wrapper>
    </Container>
  );
}

import { FormControl, InputLabel, OutlinedInput } from "@mui/material";
import React from "react";
import styled from "styled-components";

export default function IconInputText({
  fullWidth,
  variant,
  required,
  label,
  ...props
}) {
  return (
    <FormControl
      color="secondary"
      variant={variant}
      fullWidth={fullWidth}
      required={required}
    >
      <InputLabel
        sx={{
          color: "var(--white)",
        }}
        color="secondary"
        htmlFor={props?.id}
      >
        {label}
      </InputLabel>
      <CssTextField color="secondary" {...props} label={label} />
    </FormControl>
  );
}

export const CssTextField = styled(OutlinedInput)`
  &.MuiOutlinedInput-root {
    /* color: var(--white); */
    fieldset {
      border-color: var(--white);
    }
    :hover {
      fieldset {
        border-color: var(--blue);
      }
    }
  }
`;

import { createTheme } from "@mui/material/styles";

export default createTheme({
  palette: {
    primary: {
      main: "#181A20",
      contrastText: "#fff"
    },
    secondary: {
      main: "#246BFD",
      dark: "#246BFD",
      contrastText: "#fff"
    },
    gray: {
      main: "#949B9F"
    },
    white: {
      main: "#fff"
    },
    text: {
      primary: "#fff",
      secondary: "#fff",
    },
    background: {
      default: "#35383F",
      paper: "#35383F"
    },
    action: {
      active: "#246BFD",
    },
  },


  components: {
    // MuiButton: {
    //   styleOverrides: {
    //     root: ({ ownerState }) => ({
    //       ...(ownerState.color && {
    //         color: 'white',
    //       })
    //     })
    //   }
    // },
    // MuiOutlinedInput: {
    //   styleOverrides: {
    //     root: {
    //       color: "#fff",
    //       borderColor: "#fff",
    //       ":hover": {
    //         borderColor: "#fff"
    //       }
    //     }
    //   }
    // }
  },

  typography: {
    fontFamily: ['Almarai']
  }
})
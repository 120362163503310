import { BrowserRouter, Route, Routes } from "react-router-dom";
import routes from "./routes";
import { Navbar } from "./components";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <main>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        theme="dark"
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <BrowserRouter>
        <Navbar />
        <Routes>
          {routes.map((prop, index) => (
            <Route key={index} element={prop.element} path={prop.path} />
          ))}
        </Routes>
      </BrowserRouter>
    </main>
  );
}

export default App;

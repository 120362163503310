import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import { Modal as MUIModal } from "@mui/material";
import Fade from "@mui/material/Fade";

export default function Modal({ children, open, setOpen }) {
  const handleClose = () => setOpen(false);
  return (
    <MUIModal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            p: 4,
            backgroundColor: "var(--white)",
          }}
        >
          {children}
        </Box>
      </Fade>
    </MUIModal>
  );
}

import React from "react";
import FirebaseAuth from "../../config/FirebaseAuth";
import { Container, Navigation, Wrapper } from "./sidebar.styled";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { signOut } from "firebase/auth";
import { Button } from "@mui/material";

export default function Sidebar() {
  const auth = FirebaseAuth;
  const navigate = useNavigate();
  const routes = [
    {
      name: "حجز تذكرة",
      path: "new-ticket",
    },
    {
      name: "القطارات المتاحة",
      path: "trains",
    },
    {
      name: "المستخدمين",
      path: "users",
    },
    {
      name: "الاقتراحات",
      path: "suggestions",
    },
  ];

  const onSignOut = async () => {
    try {
      await signOut(auth)
        .then(() => {
          toast.success("تم تسجيل الخروج بنجاح");
          navigate("/login");
        })
        .catch((error) => {
          toast.error(error.message);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container>
      <Wrapper>
        <Navigation>
          {routes.map((route, index) => (
            <NavLink key={index} to={route.path}>
              <Button fullWidth color="white">
                {route.name}
              </Button>
            </NavLink>
          ))}
        </Navigation>
        <Button color="error" variant="contained" onClick={onSignOut}>
          تسجيل الخروج
        </Button>
      </Wrapper>
    </Container>
  );
}

import React from "react";
import { Container, MainContainer } from "./styled";
import main_background from "../../../assets/dashboard_background.mp4";

export default function Main() {
  return (
    <Container>
      <MainContainer>
        <video autoPlay loop muted>
          <source src={main_background} type="video/mp4" />
        </video>
      </MainContainer>
    </Container>
  );
}

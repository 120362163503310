import { createContext, useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import FirebaseAuth from "../../config/FirebaseAuth";
import Loader from "../loader";
import { getCurrentUser } from "../../lib";
const AuthContext = createContext();

export default function AuthProvider({ children }) {
  const [auth, setAuth] = useState(null);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(FirebaseAuth, async (snap) => {
      if (snap) setAuth(snap);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (auth) {
          const data = await getCurrentUser(auth.uid, (snapshot) => {
            if (snapshot) {
              setUser(snapshot.val());
            }
          });
          setUser(data);
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [auth]);

  return (
    <AuthContext.Provider value={{ auth, setAuth, user, setUser }}>
      {loading ? <Loader fullScreen /> : children}
    </AuthContext.Provider>
  );
}

export { AuthContext };

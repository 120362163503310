import { Button, InputAdornment, IconButton } from "@mui/material";
import { createUserWithEmailAndPassword } from "firebase/auth";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { IconInputText } from "../../components";
import FirebaseAuth from "../../config/FirebaseAuth";
import { Container, Form, Group, Title, Wrapper } from "./styled";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Link, useNavigate } from "react-router-dom";
import { addNewUser } from "../../lib";

export default function Signup() {
  const auth = FirebaseAuth;
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const [form, setForm] = useState({
    email: "",
    password: "",
    displayName: "",
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      await createUserWithEmailAndPassword(auth, form.email, form.password)
        .then(async (snap) => {
          await addNewUser(snap.user.uid, {
            displayName: snap.user.displayName,
            email: snap.user.email,
          }).then(() => {
            toast.success("تم إنشاء حساب جديد بنجاح");
            navigate("/");
          });
        })
        .catch((error) => {
          toast.error(error.message);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const onChange = (e) => {
    setForm((state) => ({ ...state, [e.target.name]: e.target.value }));
  };

  return (
    <Container>
      <Wrapper>
        <Title>تسجيل الدخول</Title>
        <Form dir="ltr" onSubmit={onSubmit}>
          <IconInputText
            required
            fullWidth
            label="Name"
            type="name"
            value={form.displayName}
            onChange={onChange}
            name="displayName"
            autoComplete="name"
          />
          <IconInputText
            required
            fullWidth
            autoComplete="new-email"
            label="Email"
            type="email"
            value={form.email}
            onChange={onChange}
            name="email"
          />
          <IconInputText
            required
            autoComplete="new-password"
            fullWidth
            color="secondary"
            label="Password"
            type={showPassword ? "text" : "password"}
            name="password"
            value={form.password}
            onChange={onChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  color="white"
                  aria-label="toggle password visibility"
                  edge="end"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          <Group>
            <Button type="submit" variant="contained" color="secondary">
              إنشاء حساب
            </Button>
            <Link to="/login">
              <Button color="gray">لديك حساب ؟ تسجيل دخول</Button>
            </Link>
          </Group>
        </Form>
      </Wrapper>
    </Container>
  );
}

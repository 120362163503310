import React, { useEffect, useState } from "react";
import { deleteUserDoc, getUsersList, updateUser } from "../../../lib";
import {
  Actions,
  Container,
  ModalActions,
  ModalContainer,
  ModalInputs,
  Wrapper,
} from "./styled";
import { Loader, Modal, useAuth } from "../../../components";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import CheckIcon from "@mui/icons-material/Check";
import {
  Button,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
} from "@mui/material";
import { toast } from "react-toastify";

export default function UsersList() {
  const [users, setUsers] = useState({});
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const { auth } = useAuth();
  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    const unsubscribe = async () => {
      setLoading(true);
      const response = await getUsersList((snapshot) => {
        setUsers(snapshot.val());
      });
      setUsers(response);
      setLoading(false);
    };
    unsubscribe();
  }, []);

  useEffect(() => {
    if (users && Object.keys(users).length) {
      setLoading(true);
      setRows([]);
      for (let key in users) {
        setRows((prev) => [...prev, users[key]]);
      }
      setLoading(false);
    }
  }, [users]);

  const columns = [
    { field: "id", headerName: "ID" },
    { field: "name", headerName: "DisplayName" },
    { field: "email", headerName: "Email" },
    { field: "admin", headerName: "Admin" },
    { field: "actions", headerName: "" },
  ];

  const onDeleteUser = async (id) => {
    try {
      setLoading(true);
      await deleteUserDoc(id)
        .then(() => {
          toast.success("تم حذف المستخدم بنجاح");
          setOpenDeleteModal(false);
          setSelectedUser(null);
        })
        .catch((error) => {
          toast.error(error.message);
        });
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const onUserChange = async (e) => {
    setSelectedUser((state) => ({ ...state, [e.target.name]: e.target.value }));
  };

  const onEditUser = async () => {
    try {
      setLoading(true);
      await updateUser(selectedUser?.uid, selectedUser)
        .then(() => {
          toast.success("تم تعديل المستخدم بنجاح");
          setOpenEditModal(false);
          setSelectedUser(null);
        })
        .catch((error) => {
          toast.error(error.message);
        });
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  if (!auth || loading) return <Loader fullScreen />;
  else
    return (
      <Container>
        <Wrapper>
          <TableContainer>
            <Table stickyHeader arrows>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.field}>
                      {column.headerName}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((shot) => (
                    <TableRow key={shot.uid}>
                      <TableCell color="white">{shot.uid}</TableCell>
                      <TableCell color="white">{shot.displayName}</TableCell>
                      <TableCell color="white">{shot.email}</TableCell>
                      <TableCell color="white">
                        {shot.admin && <CheckIcon color="success" />}
                      </TableCell>
                      <TableCell>
                        <Actions>
                          <Button
                            size="small"
                            variant="contained"
                            color="error"
                            onClick={() => {
                              setOpenDeleteModal(true);
                              setSelectedUser(shot);
                            }}
                          >
                            مسح
                          </Button>
                          <Modal
                            open={openDeleteModal}
                            setOpen={setOpenDeleteModal}
                          >
                            <ModalContainer>
                              <h3>هل انت متاكد من مسح هذا المستخدم ؟</h3>
                              <ModalActions>
                                <Button
                                  onClick={() =>
                                    onDeleteUser(selectedUser?.uid)
                                  }
                                  variant="contained"
                                  color="error"
                                >
                                  مسح
                                </Button>
                                <Button
                                  onClick={() => {
                                    setOpenDeleteModal(false);
                                    setSelectedUser(null);
                                  }}
                                  variant="contained"
                                  color="primary"
                                >
                                  إلغاء
                                </Button>
                              </ModalActions>
                            </ModalContainer>
                          </Modal>
                          <Button
                            size="small"
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                              setOpenEditModal(true);
                              setSelectedUser(shot);
                            }}
                          >
                            تعديل
                          </Button>
                          <Modal
                            open={openEditModal}
                            setOpen={setOpenEditModal}
                          >
                            <ModalContainer>
                              <h3>تعديل المستخدم</h3>
                              <ModalInputs>
                                <TextField
                                  fullWidth
                                  color="primary"
                                  variant="outlined"
                                  label="Email"
                                  value={selectedUser?.email}
                                  name="email"
                                  onChange={onUserChange}
                                />
                                <TextField
                                  fullWidth
                                  onChange={onUserChange}
                                  color="primary"
                                  variant="outlined"
                                  label="displayName"
                                  value={selectedUser?.displayName}
                                  name="displayName"
                                />
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        onChange={() =>
                                          setSelectedUser((state) => ({
                                            ...state,
                                            admin: !selectedUser?.admin,
                                          }))
                                        }
                                        checked={selectedUser?.admin}
                                      />
                                    }
                                    label="Admin"
                                  />
                                </FormGroup>
                              </ModalInputs>
                              <ModalActions>
                                <Button
                                  onClick={onEditUser}
                                  variant="contained"
                                  color="secondary"
                                >
                                  حفظ
                                </Button>
                                <Button
                                  onClick={() => {
                                    setOpenEditModal(false);
                                    setSelectedUser(null);
                                  }}
                                  variant="contained"
                                  color="primary"
                                >
                                  إلغاء
                                </Button>
                              </ModalActions>
                            </ModalContainer>
                          </Modal>
                        </Actions>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            color="secondary"
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          ></TablePagination>
        </Wrapper>
      </Container>
    );
}

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './config/globals.style.css'
import { ThemeProvider } from "@mui/material/styles";
import theme from "./config/theme";
import AuthProvider from "./components/context/AuthContext";


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme} >
      <AuthProvider>
        <App />
      </AuthProvider>
    </ThemeProvider>
  </React.StrictMode>
);

import Login from "./auth/Login";
import Signup from "./auth/Signup";
import Dashboard from "./dashborad";
import Home from "./home";
import Privacy from "./privacy";
import Profile from "./profile";



const routes = [
  {
    path: '/',
    element: <Home />
  },
  {
    path: '/login',
    element: <Login />
  },
  {
    path: '/signup',
    element: <Signup />
  },
  {
    path: "/dashboard/*",
    element: <Dashboard />
  },
  {
    path: "/privacy",
    element: <Privacy />
  },
  {
    path: "/profile",
    element: <Profile />
  }
]

export default routes;
import React, { useEffect, useState } from "react";
import { Loader } from "../../../components";
import { getTrains } from "../../../lib";
import {
  Container,
  TrainCard,
  TrainCardHeader,
  TrainsContainer,
  TrainTicketsCounter,
  Wrapper,
} from "./styled";

export default function TrainsList() {
  const [data, setData] = useState({});
  const [trains, setTrains] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    try {
      (async () => {
        setLoading(true);
        const response = await getTrains((snapshot) => {
          setData(snapshot.val());
        });
        setData(response);
        setLoading(false);
      })();
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    try {
      if (data) {
        setTrains([]);
        for (const prop in data) {
          setTrains((prevState) => [
            ...prevState,
            {
              id: prop,
              seats: data[prop].seats,
              unavailable: data[prop].unavailable,
              latitude: data[prop].latitude,
              longitude: data[prop].longitude,
              tickets: data[prop].tickets,
            },
          ]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [data]);

  console.log(trains);

  if (!trains.length || loading) return <Loader fullScreen />;
  else
    return (
      <Container>
        <Wrapper>
          <TrainsContainer>
            {trains &&
              trains.map((prop, index) => (
                <TrainCard key={index} to={prop.id}>
                  <TrainCardHeader>{prop.id}</TrainCardHeader>
                  <TrainTicketsCounter total={prop.seats}>
                    {prop.unavailable} /
                  </TrainTicketsCounter>
                </TrainCard>
              ))}
          </TrainsContainer>
        </Wrapper>
      </Container>
    );
}

import styled from "styled-components";

export const Container = styled.div``
export const Wrapper = styled.div`
  padding: 1rem;
`
export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 1rem;
  margin-bottom: 1rem;
`
export const Group = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: .5rem;
`
export const SliderContainer = styled.div`
  display: flex;
  margin-top: 1rem;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 100%;
  span {
   white-space: nowrap;
  }
`
export const Actions = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;

`

export const TicketsContainer = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
export const TicketCard = styled.div`
  width: 90%;
  background-color: var(--light-dark);
  overflow: hidden;
  border-radius: 1rem;
  cursor: pointer;
  transition: 300ms;
  :hover {
    filter: brightness(.9);
    transform: scale(1.04);
  }
  `
export const TicketCardHeader = styled.div`
  display: flex;
  gap: 1rem;
  padding: .5rem;
  align-items: center;
  justify-content: center;
  background-color: var(--blue);
  `
export const TicketCardContent = styled.div`
  display: flex;
  padding: 1rem;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  flex-wrap: wrap;
  margin-top: 1rem;
`

export const TicketCardTag = styled.div`
  display: flex;
  gap: .5rem;
  align-items: center;
  justify-content: center;
  background-color: var(--dark);
  border-radius: .5rem;
  overflow: hidden;
  span {
    padding: .2rem .5rem;
    background-color: var(--blue);
  }
  p {
    padding: 0 .5rem;
  }
`
export const TicketCardPrice = styled.div`
  display: flex;
  align-items: center;
  padding: 2rem;
  justify-content: center;
  font-size: 3em;
  font-weight: bold;
  ::before {
    content: "EGP";
    color: var(--red);
  }
`
export const TicketCardActions = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  justify-content: center;
`
export const ModalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  color: var(--dark);
`
export const ModalActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`
export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  font-weight: bold;
  font-size: 1.2rem;
`
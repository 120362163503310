import styled from "styled-components";

export const Card = styled.div`
  width: 100%;
  background-color: var(--light-dark);
  padding: 1rem;
  border-radius: 1rem;
  margin-top: 1rem;


  display: flex;
  flex-direction: column;
  gap: 1rem;
`
export const CardContent = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 1rem;
  span {
    text-align: end;
    min-width: 150px;
    background-color: var(--blue);
    padding: 0.2rem;
    border-radius: 0.5rem;
  }
`
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
export default function SelectList({ required, items, label, value, onChange }) {
  return (
    <FormControl
      variant="outlined"
      color="secondary"
      required={required}
      sx={{ minWidth: 200, m: 1 }}
    >
      <InputLabel>{label}</InputLabel>
      <Select label={label} value={value} onChange={onChange}>
        {items.map((prop, index) => (
          <MenuItem key={index} value={prop.id}>
            {prop.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

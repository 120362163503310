import React, { useEffect } from "react";
import { Loader } from "../../../../components";
import { getSuggestions } from "../../../../lib";
import { Container, Wrapper } from "../styled";
import { Card, CardContent, CardHeader, SuggestionsContainer } from "./styled";

export default function Suggestions() {
  const [data, setData] = React.useState(null);
  const [suggestions, setSuggestions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    try {
      const fetchData = async () => {
        setLoading(true);
        const response = await getSuggestions((snapshot) => {
          setData(snapshot.val());
        });
        setData(response);
        setLoading(false);
      };

      fetchData();
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    try {
      if (data) {
        setSuggestions([]);
        for (const prop in data) {
          for (const message in data[prop]) {
            setSuggestions((prevState) => [...prevState, data[prop][message]]);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [data]);

  if (!suggestions.length || loading) return <Loader fullScreen />;
  else
    return (
      <Container>
        <Wrapper>
          {suggestions.map((suggestion, index) => (
            <Card dir="ltr" key={index}>
              <CardContent>
                <span>الرقم التعريفي</span>
                {suggestion?.uid}
              </CardContent>
              <CardContent>
                <span>الرسالة</span>
                {suggestion?.content}
              </CardContent>
            </Card>
          ))}
        </Wrapper>
      </Container>
    );
}

import { Button, InputAdornment, IconButton } from "@mui/material";
import { signInWithEmailAndPassword } from "firebase/auth";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { IconInputText } from "../../components";
import FirebaseAuth from "../../config/FirebaseAuth";
import { Container, Form, Group, Title, Wrapper } from "./styled";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Link, useNavigate } from "react-router-dom";

export default function Login() {
  const auth = FirebaseAuth;
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const [form, setForm] = useState({
    email: "",
    password: "",
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, form.email, form.password)
        .then(() => {
          toast.success("تم تسجيل الدخول بنجاح");
          navigate("/");
        })
        .catch((error) => {
          toast.error(error.message);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const onChange = (e) => {
    setForm((state) => ({ ...state, [e.target.name]: e.target.value }));
  };

  return (
    <Container>
      <Wrapper>
        <Title>تسجيل الدخول</Title>
        <Form dir="ltr" onSubmit={onSubmit}>
          <IconInputText
            required
            fullWidth
            label="Email"
            type="email"
            value={form.email}
            onChange={onChange}
            name="email"
          />
          <IconInputText
            required
            fullWidth
            color="secondary"
            label="Password"
            type={showPassword ? "text" : "password"}
            name="password"
            value={form.password}
            onChange={onChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  color="white"
                  aria-label="toggle password visibility"
                  edge="end"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          <Group>
            <Button type="submit" variant="contained" color="secondary">
              تسجيل الدخول
            </Button>
            <Button color="gray">نسيت كلمة المرور؟</Button>
          </Group>
          <Link to="/signup">
            <Button fullWidth variant="contained" color="secondary">
              ليس لديك حساب ؟ إنشاء حساب
            </Button>
          </Link>
        </Form>
      </Wrapper>
    </Container>
  );
}

import { getDatabase, ref, get, onValue, update, remove, set, push } from "firebase/database";

export const getUsersList = async (onChange) => {
  const db = getDatabase();
  const snapRef = ref(db, "users")
  const data = await get(snapRef);
  onValue(snapRef, onChange);
  return data.val();
}
export const getSuggestions = async (onChange) => {
  const db = getDatabase();
  const snapRef = ref(db, "suggestions")
  const data = await get(snapRef);
  onValue(snapRef, onChange);
  return data.val();
}

export const getCurrentUser = async (uid, onChange) => {
  const db = getDatabase();
  const userRef = ref(db, `users/${uid}`);
  const user = await get(userRef);
  onValue(userRef, onChange)
  return user.val();
}

export const updateUser = async (uid, data) => {
  const db = getDatabase();
  const userRef = ref(db, `users/${uid}`);
  return update(userRef, data)
}

export const deleteUserDoc = async (uid) => {
  const db = getDatabase();
  const userRef = ref(db, `users/${uid}`);
  return remove(userRef)
}

export const getTrains = async (onChange) => {
  const db = getDatabase();
  const trainRef = ref(db, 'trains');
  const data = await get(trainRef);
  onValue(trainRef, onChange);
  console.log(data);
  return data.val();
}


export const getTrainTicketsList = async (start, end) => {
  const db = getDatabase();
  const dataRef = ref(db, `stations/${start}/${end}`);
  const data = await get(dataRef)
  return data.val()
}

export const getTrainById = async (id, onChange) => {
  const db = getDatabase();
  const trainRef = ref(db, `trains/${id}`);
  const data = await get(trainRef);
  onValue(trainRef, onChange);
  console.log(data);
  return data.val();
}

export const addNewUser = async (uid, data) => {
  const db = getDatabase();
  const userRef = ref(db, `users/${uid}`);
  return set(userRef, {
    admin: false,
    uid: uid,
    ...data
  })
}


export const addNewUnlistedTicket = async (data) => {
  const db = getDatabase();
  const dataRef = ref(db, "unlistedTicket")
  const newTicketRef = push(dataRef);
  return set(newTicketRef, data)
}

export const updateTrainTickets = async (id, { tickets, seats }) => {
  const trainRef = `trains/${id}`
  const db = getDatabase();
  const dataRef = ref(db, trainRef)
  const isData = await get(dataRef);
  if (isData.exists()) {
    const ticketsRef = ref(db, `${trainRef}/tickets`)
    const newTicketRef = push(ticketsRef);
    const currentData = await isData.val();
    await set(newTicketRef, tickets)
    return update(dataRef, {
      unavailable: currentData.unavailable + seats,
    })
  } else {
    return set(dataRef, {
      latitude: 0.0,
      longitude: 0.0,
      tickets: [tickets],
      seats: 100,
      unavailable: seats
    })
  }
}


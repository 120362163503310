import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div``
export const Wrapper = styled.div`
  padding: 1rem;
`

export const MainContainer = styled.div`
  height: calc(100vh - 60px);
  width: 100%;
  overflow: hidden;
  position: relative;
  
  video {
    width: 110%;
  }

  ::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
`
export const Actions = styled.div`
  display: flex;
  gap: 1rem;
`
export const ModalContainer = styled.div`
  color: var(--dark);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const ModalActions = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  gap: 1rem;
`

export const ModalInputs = styled.div`
  width: 100%;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  input {
    color: var(--dark);
  }
`



export const TrainsContainer = styled.div`

`
export const TrainCard = styled(Link)`
  width: 100%;
  background-color: var(--light-dark);
  padding: 1rem;
  border-radius: 1rem;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  :hover {
    filter: brightness(0.9);
  }
`
export const TicketTrainCard = styled.div`
  width: 100%;
  background-color: var(--light-dark);
  padding: 1rem;
  border-radius: 1rem;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const TrainCardHeader = styled.div`
  font-size: 4rem;
  font-weight: bold;
  ::before {
    content: "T";
    color: var(--blue)
  }
`
export const TrainTicketsCounter = styled.div`
  font-size: 4rem;
  font-weight: bold;
  ::after {
    content: " ${(props) => props.total}";
    color: var(--red)
  }
`

export const TicketsListContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  flex-direction: column;
`
export const InputsContainer = styled.div``
import styled from "styled-components";

export const Container = styled.div``
export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 60px);
  font-size: 3em;
  font-weight: bold;
  text-shadow: 2px 3px var(--red);
`
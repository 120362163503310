import styled from "styled-components";

export const Container = styled.div``
export const Wrapper = styled.div`
  min-height: calc(100vh - 60px);
  display: grid;
  grid-template-columns: 1fr 4fr;
  
`
export const Content = styled.div`
  max-height: calc(100vh - 60px);
  overflow-y: scroll;
`